<template>
  <DxSankey
    id="sankey"
    :data-source="data"
    source-field="source"
    target-field="target"
    weight-field="weight"
    title="Mapa błędów"
  >
    <DxTooltip
      :enabled="true"
      :customize-link-tooltip="customizeLinkTooltip"
    />
    <DxNode
      :width="8"
      :padding="30"
    />
    <DxLink color-mode="gradient"/>

  </DxSankey>
</template>
<script>

import DxSankey, {
  DxTooltip,
  DxNode,
  DxLink,
} from 'devextreme-vue/sankey'

export default {
  components: {
    DxSankey,
    DxTooltip,
    DxNode,
    DxLink,
  },
  data() {
    return {
      data: [
        { source: 'Spain', target: 'United States of America', weight: 1 },
        { source: 'Germany', target: 'United States of America', weight: 8 },
        { source: 'France', target: 'United States of America', weight: 4 },
        { source: 'Germany', target: 'Great Britain', weight: 2 },
        { source: 'France', target: 'Great Britain', weight: 4 },
        { source: 'United States of America', target: 'Australia', weight: 6 },
        { source: 'United States of America', target: 'New Zealand', weight: 5 },
        { source: 'United States of America', target: 'Japan', weight: 3 },
        { source: 'Great Britain', target: 'New Zealand', weight: 4 },
        { source: 'Great Britain', target: 'Japan', weight: 1},
        { source: 'Japan', target: 'test', weight: 1}
      ]
    }
  },
  methods: {
    customizeLinkTooltip(info) {
      return {
        html: `<b>From:</b> ${info.source}<br/><b>To:</b> ${info.target}<br/><b>Weight:</b> ${info.weight}`
      }
    }
  }
}
</script>
<style>
#sankey {
  height: 440px;
}
</style>
